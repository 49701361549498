var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content d-flex flex-column flex-column-fluid"},[_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"row justify-content-center my-10 px-8 my-lg-15 px-lg-10"},[_c('div',{staticClass:"col-xl-12 col-xxl-7"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateWarehouseProduct)}}},[_c('h3',{staticClass:"mb-10 font-weight-bold text-dark"},[_vm._v(" Warehouse Product: ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Belong to Client","label-for":"input-1"}},[_c('label',{staticClass:"form-control-lg"},[_vm._v(_vm._s(_vm.belongTo))])]),_c('validation-provider',{attrs:{"rules":"required","name":"sku"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"SKU","label-for":"input-1"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-1","type":"text","name":"sku","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Product","label-for":"input-2"}},[_c('b-input-group',{attrs:{"size":"lg"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-2","type":"text","name":"name","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Description","label-for":"input-3"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-3","type":"text","name":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Rack","label-for":"input-3"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-3","type":"text","name":"rack"},model:{value:(_vm.rack),callback:function ($$v) {_vm.rack=$$v},expression:"rack"}})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","id":"input-group-1","label":"Box","label-for":"input-3"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"input-3","type":"text","name":"box"},model:{value:(_vm.box),callback:function ($$v) {_vm.box=$$v},expression:"box"}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('submit-button',{attrs:{"loading":_vm.loading}})],1)])]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }