<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(updateWarehouseProduct)">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      Warehouse Product:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <b-form-group label-cols-sm="3" id="input-group-1" label="Belong to Client" label-for="input-1">
                          <label class="form-control-lg">{{ belongTo }}</label>
                        </b-form-group>

                        <validation-provider rules="required" name="sku" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="input-group-1" label="SKU" label-for="input-1">
                            <b-form-input
                              v-model="sku"
                              id="input-1"
                              type="text"
                              name="sku"
                              class="form-control form-control-lg form-control-solid"
                              :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider rules="required" name="name" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="input-group-1" label="Product" label-for="input-2">
                            <b-input-group size="lg">
                              <b-form-input
                                v-model="name"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="name"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Description" label-for="input-3">
                          <b-form-input
                            v-model="description"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="description"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Rack" label-for="input-3">
                          <b-form-input
                            v-model="rack"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="rack"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Box" label-for="input-3">
                          <b-form-input
                            v-model="box"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="box"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateProduct } from "@/api/warehouse.api";
import { useNotify } from "@/utils/notify";

import { ref, toRefs, reactive } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "updateWarehouseProduct",
  components: { ValidationProvider, ValidationObserver },
  props: {
    productObject: Object
  },
  setup(props, context) {
    const { notify } = useNotify(context);
    const { id, sku, name, description, rack, box, belong_to: belongTo } = toRefs(reactive(props.productObject));

    const loading = ref(false);

    const updateWarehouseProduct = async function() {
      loading.value = true;

      const payload = {
        sku: sku.value,
        name: name.value,
        description: description.value,
        rack: rack.value,
        box: box.value
      };

      try {
        await updateProduct(id.value, payload);
        notify("Product updated", `The product is updated`);
        context.root.$router.push({ name: "ListWarehouseProduct" });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      sku,
      name,
      description,
      rack,
      box,
      belongTo,

      updateWarehouseProduct,
      loading
    };
  }
};
</script>
