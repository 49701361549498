import ApiService from "@/core/services/api.service";

const WAREHOUSE_END_POINT = "/warehouse";

const getDashboard = () => ApiService.get(`${WAREHOUSE_END_POINT}/dashboard`);

const getAllProduct = () => ApiService.get(`${WAREHOUSE_END_POINT}/product`);

const getAllProductByClient = client => ApiService.get(`${WAREHOUSE_END_POINT}/product/client/${client}`);

const getProductMovement = id => ApiService.get(`${WAREHOUSE_END_POINT}/product/${id}`);

const createProduct = param => ApiService.post(`${WAREHOUSE_END_POINT}/product`, param);

const updateProduct = (id, param) => ApiService.put(`${WAREHOUSE_END_POINT}/product/${id}`, param);

const inbound = param => ApiService.post(`${WAREHOUSE_END_POINT}/inbound`, param);

const outbound = param => ApiService.post(`${WAREHOUSE_END_POINT}/outbound`, param);

export {
  getDashboard,
  getAllProduct,
  getAllProductByClient,
  getProductMovement,
  createProduct,
  updateProduct,
  inbound,
  outbound
};
